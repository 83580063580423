import { AuthGuardRoutes } from './../../auth.guard.routes';
import { BotoesDataTable } from './../../model/botoes-datatable.model';
import { Page } from './../../util/page';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { CalendarUtil } from 'src/app/util/calendar.util';

@Component({
  selector: 'app-sca-datatable',
  templateUrl: './sca-datatable.component.html',
  styleUrls: ['./sca-datatable.component.css']
})
export class ScaDatatableComponent implements OnInit {

  @ViewChild("dt") dt: Table;

  @Input()
  result: Page<any>;

  @Input()
  cols: any[] = [];

  @Input()
  sortField: string = "";

  @Input()
  linkVoltar: string = null;

  @Input()
  buttons: BotoesDataTable[] = []

  @Output()
  onLazyLoad = new EventEmitter<Table>();

  @Output()
  toggleRow = new EventEmitter<any>();

  @Output()
  buttonClick = new EventEmitter<any>();

  @Input() resourceUrl: string;
  @Input() sortOrder: string;
  @Input() nomeRelatorio: string;

  @Input() roleExportacao: string;

  @Input() filtroExportacao: any;

  registroSelecionado: any;

  br = CalendarUtil.getTraducao();

  constructor(private router: Router, private authGuardRoutes: AuthGuardRoutes) { }

  ngOnInit() {
    this.dt.filterDelay = 400;
    this.dt.sortOrder = 1;
  }

  lazyLoad(event) {
    this.onLazyLoad.emit(this.dt);
  }

  onRowSelectUnselect() {
    this.toggleRow.emit(this.registroSelecionado);
  }

  onButtonClick(name, event) {
    this.buttonClick.emit(name);
  }

  voltar() {
    this.router.navigateByUrl(this.linkVoltar);
  }

  paginationFooterText(): string {
    const registros = this.totalRecords() === 1 ? 'registro' : 'registros';
    return `Exibindo: ${this.currentFirstIndex()} a ${this.currentLastIndex()} de ${this.totalRecords()} ${registros}`;
  }

  currentFirstIndex(): number {
    return this.dt.first + 1;
  }

  currentLastIndex(): number {
    const currentLastIndex: number = this.dt.first + this.dt.rows;
    if (currentLastIndex > this.totalRecords()) {
      return this.totalRecords();
    }
    return currentLastIndex;
  }

  totalRecords(): number {
    return this.dt.totalRecords;
  }

  clearString(eventValue):string{
      return eventValue.replace(/[/.-]/g,"");
  }
}
