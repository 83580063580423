import * as _ from 'lodash';
import { NumeroUtils } from './numero.util';

export const CPF_LENGTH = 11;
export const CPF_BASE_LENGTH = 9;
export const CNPJ_LENGTH = 14;
export const CNPJ_BASE_LENGTH = 8;

export const MASK_CNPJ = '99.999.999/9999-99';
export const MASK_CPF = '999.999.999-99';

export class CNPJUtils {
  public static formatar(v: string | number): string {
    if (!v && v !== 0) return '';
    v = v.toString();
    v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
    v = v.slice(0, CNPJ_LENGTH);
    v = this.mascarar(v);
    return v;
  }
  
  public static mascarar(v: string): string {
    // 99.999.999/9999-99
    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2}\.\d{3})(\d)/, '$1.$2'); // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/^(\d{2}\.\d{3}\.\d{3})(\d)/, '$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }

  public static calcularDigito(v: string): string {
    const pesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    return (!v) ? v : NumeroUtils.calcularDigito(v, pesos);
  }

  public static validar(v: string): boolean {
    if (!v || !v.match(/(^\d{14})$/)) {
      return false;
    }
    const digito1 = this.calcularDigito(v.substring(0, 12));
    const digito2 = this.calcularDigito(v.substring(0, 12) + digito1);
    return (v === (v.substring(0, 12) + digito1 + digito2));
  }

  public static extrairBase(v: string): string {
    return (this.validar(v)) ? v.substring(0, CNPJ_BASE_LENGTH) : undefined;
  }

  public static calcularDigitos(base: string, ordem: string): string {
    if (!base || !base.match(/(^\d{8})$/) || !ordem || !ordem.match(/(^\d{4})$/)) {
      return undefined;
    }
    const digito1 = this.calcularDigito(base + ordem);
    const digito2 = this.calcularDigito(base + ordem + digito1);
    return digito1 + digito2;
  }

  public static completar(base: string, ordem: string): string {
    if (!base || !base.match(/(^\d{8})$/) || !ordem || !ordem.match(/(^\d{4})$/)) return undefined;
    return base + ordem + this.calcularDigitos(base, ordem);
  }

  public static padStart(v: string | number, length: number = CNPJ_LENGTH): string {
    if (!v && v !== 0) return '';
    return _.padStart(v.toString(), length, '0');
  }

}

export class CPFUtils {

  public static formatar(v: string | number): string {
    if (!v && v !== 0) return '';
    v = v.toString();
    v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
    v = v.slice(0, CPF_LENGTH);
    v = this.mascarar(v);
    return v;
  }
  
  public static mascarar(v: string): string {
    // 999.999.999-99
    v = v.replace(/^(\d{3})(\d)/, '$1.$2'); // Coloca ponto entre o terceiro e o quarto dígitos
    v = v.replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2'); // Coloca ponto entre o sexto e o setimo dígitos
    v = v.replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2'); // Coloca um hifen entre o nono e o decimo dígitos
    return v;
  }

  public static calcularDigito(v: string): string {
    const pesos = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
    return (!v) ? v : NumeroUtils.calcularDigito(v, pesos);
  }

  public static validar(v: string): boolean {
    if (!v || !v.match(/(^\d{11})$/)) {
      return false;
    }
    const digito1 = this.calcularDigito(v.substring(0, 9));
    const digito2 = this.calcularDigito(v.substring(0, 9) + digito1);
    return (v === (v.substring(0, 9) + digito1 + digito2));
  }

  public static extrairBase(v: string): string {
    return (this.validar(v)) ? v.substring(0, 9) : undefined;
  }

  public static calcularDigitos(base: string): string {
    if (!base || !base.match(/(^\d{9})$/)) {
      return undefined;
    }
    const digito1 = this.calcularDigito(base);
    const digito2 = this.calcularDigito(base + digito1);
    return digito1 + digito2;
  }

  public static completar(base: string): string {
    if (!base || !base.match(/(^\d{9})$/)) return base;
    return base + this.calcularDigitos(base);
  }

  public static padStart(v: string | number, length: number = CPF_LENGTH): string {
    if (!v && v !== 0) return '';
    return _.padStart(v.toString(), length, '0');
  }
}

export class NiUtils {
  public static formatar(v: string): string {
    if (!v) return '';
    return v.length == CPF_LENGTH ? CPFUtils.formatar(v) : CNPJUtils.formatar(v);
  }

  public static validar(v: string): boolean {
    if (!v) return true;
    return v.length == CPF_LENGTH ? CPFUtils.validar(v) : CNPJUtils.validar(v);
  }  
}
