import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'valueLabelPipe'
})
export class ValueLabelPipe implements PipeTransform {
    /**
     * Procura o objeto no array 'itens' e retorna o label
     * @param value 
     * @param itens
     * @returns label do objeto encontrado
     */
    transform(value: any, itens: {value: any, label: string}[]): string {
        const item = itens.find(item => item.value == value);

        return item ? item.label : '';
    }
}
