import {PRIMENG_IMPORTS} from './primeng-imports';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutes} from './app.routes';

import {AppComponent} from './app.component';
import {AppMenuComponent, AppSubMenuComponent} from './app.menu.component';
import {AppTopbarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';

import {AppBreadcrumbComponent} from './breadcrumb/app.breadcrumb.component';
import {BreadcrumbService} from './breadcrumb/breadcrumb.service';
import {AppRightpanelComponent} from './app.rightpanel.component';
import {AppInlineProfileComponent} from './app.profile.component';

import {DiarioErrosComponent} from './diario-erros/diario-erros.component';
import {LoginComponent} from './login/login.component';
import {SharedModule} from './shared/shared.module';

import {
    AbstractAuthentication,
    AbstractAuthorization,
    AbstractLogin,
    AbstractToken,
    AccessbilityModule,
    AUTH_CONFIG,
    AuthenticationService,
    AuthorizationService,
    ClipboardModule,
    DirectivesModule,
    ErrorModule,
    JWTTokenService,
    LoginService,
    SecurityModule,
    VersionTagModule
} from '@nuvem/angular-base';

import {DatatableModule, ErrorStackModule, PageNotificationModule} from '@nuvem/primeng-components';
import {BlockUIModule} from 'ng-block-ui';
import {SuccessInterceptor} from './interceptor/success.interceptor';
import {environment} from '../environments/environment';
import {LoginSuccessComponent} from './login-success/login-success.component';
import {LogoutComponent} from "./logout/logout.component";
import { LoginFailedComponent } from './login-failed/login-failed.component';
// import { SimNaoPipe } from './pipes/simNao.pipe';
import { AppDirectivesModule } from './directives/app.directives.module';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        PRIMENG_IMPORTS,
        AccessbilityModule.forRoot(),
        VersionTagModule.forRoot(),
        SharedModule.forRoot(),
        PageNotificationModule.forRoot(),
        BlockUIModule.forRoot(),
        DatatableModule.forRoot(),
        ErrorStackModule.forRoot(),
        ClipboardModule.forRoot(),
        ErrorModule.forRoot(),
        SecurityModule.forRoot(),
        DirectivesModule,
        AppDirectivesModule
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        AppRightpanelComponent,
        AppInlineProfileComponent,
        DiarioErrosComponent,
        LoginComponent,
        LoginSuccessComponent,
        LogoutComponent,
        LoginFailedComponent,
        // SimNaoPipe
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },

        { provide: AUTH_CONFIG, useValue: environment.auth },
        { provide: LoginService, deps: [HttpClient, AUTH_CONFIG] },
        { provide: JWTTokenService, deps: [AUTH_CONFIG] },
        { provide: AuthorizationService, deps: [HttpClient, AUTH_CONFIG] },
        { provide: AbstractLogin, useClass: LoginService },
        { provide: AbstractToken, useClass: JWTTokenService },
        { provide: AuthenticationService, deps: [AUTH_CONFIG] },
        { provide: AbstractAuthorization, useClass: AuthorizationService },
        { provide: AbstractAuthentication, useClass: AuthenticationService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SuccessInterceptor,
            multi: true
          },
        BreadcrumbService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
