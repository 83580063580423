import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MenuItem} from 'primeng/primeng';
import {AppComponent} from './app.component';
import {PermissoesUtil} from "./util/permissoes.util";

@Component({
    selector: 'app-menu',
    template: `
        <ul app-submenu [item]="model" root="true" class="ultima-menu ultima-main-menu clearfix"
            [reset]="reset" visible="true" parentActive="true"></ul>
    `
})
export class AppMenuComponent implements OnInit {

    @Input() reset: boolean;

    model: any[];

    constructor(public app: AppComponent) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Página Inicial',
                icon: 'home',
                routerLink: ['/'],
                role: PermissoesUtil.PERMISSOES_BASE
            },
            {
                label: 'Cadastro',
                icon: 'add',
                role: [PermissoesUtil.PERFIL_ACESSAR, PermissoesUtil.SISTEMA_ACESSAR, PermissoesUtil.ACAO_ACESSAR, PermissoesUtil.GESTAO_UNIDADES_ACESSAR, PermissoesUtil.SISTEMAS_INTEGRADOS_ACESSAR, PermissoesUtil.CONTROLE_ACESSO_ACESSAR],
                items: [
                    {
                        label: 'Perfil',
                        routerLink: ['/cadastro/perfil'],
                        icon: 'list',
                        role: PermissoesUtil.PERFIL_ACESSAR
                    },
                    {
                        label: 'Sistema',
                        routerLink: ['/cadastro/sistema'],
                        icon: 'list',
                        role: PermissoesUtil.SISTEMA_ACESSAR
                    },
                    {
                        label: 'Ações',
                        routerLink: ['/cadastro/acao'],
                        icon: 'list',
                        role: PermissoesUtil.ACAO_ACESSAR
                    },
                    {
                        label: 'Gestão de Unidades',
                        routerLink: ['/cadastro/gestao-unidades'],
                        icon: 'list',
                        role: PermissoesUtil.GESTAO_UNIDADES_ACESSAR
                    },
                    {
                        label: 'Sistemas Integrados',
                        routerLink: ['/cadastro/sistemas-integrados'],
                        icon: 'list',
                        role: PermissoesUtil.SISTEMAS_INTEGRADOS_ACESSAR
                    },
                    {
                        label: 'Controle Acesso por Atividade',
                        routerLink: ['/cadastro/controle-acesso'],
                        icon: 'list',
                        style: {'font-size': '13px'},
                        role: PermissoesUtil.CONTROLE_ACESSO_ACESSAR
                    },
                    {
                        label: 'Vínculo de Representantes',
                        routerLink: ['/cadastro/vinculo-representante'],
                        icon: 'list',
                        style: {'font-size': '13px'},
                        role: PermissoesUtil.REPRESENTANTE_LISTAR
                    }
                ]
            },
            {
                label: 'Administração',
                icon: 'settings',
                role: PermissoesUtil.ATRIBUIR_PERFIL_ACESSAR,
                items: [
                    {
                        label: 'Conceder Perfil',
                        routerLink: ['/administracao/conceder-perfil'],
                        icon: 'list',
                        role: PermissoesUtil.ATRIBUIR_PERFIL_ACESSAR
                    },
                    {
                        label: 'Gerenciar Usuário',
                        routerLink: ['/gerenciar-usuario'],
                        icon: 'list'
                    },
                    {
                        label: 'Vincular Aividade',
                        routerLink: ['/vincular-atividade'],
                        icon: 'list'
                    }
                ]
            },
            {
                label: 'Relatórios',
                icon: 'settings',
                role: PermissoesUtil.RELATORIOS_LOG_ACESSO_API_ACESSAR,
                items: [
                    {
                        label: 'Relatório de Consulta CPF por Pessoa',
                        routerLink: ['/relatorios/relatorio-pessoa'],
                        icon: 'list',
                        role: PermissoesUtil.RELATORIOS_LOG_ACESSO_API_ACESSAR
                    },
                    {
                        label: 'Relatório de Consulta CPF por Mês',
                        routerLink: ['/relatorios/relatorio-mes'],
                        icon: 'list',
                        role: PermissoesUtil.RELATORIOS_LOG_ACESSO_API_ACESSAR
                    }
                ]
            },
            {
                label: 'Log de Auditoria',
                icon: 'description',
                role: [PermissoesUtil.LOG_AUDITORIA_ACESSAR],
                items: [
                    {
                        label: 'Consulta Log de Auditoria',
                        routerLink: ['/auditoria/log-auditoria'],
                        icon: 'list',
                        role: PermissoesUtil.LOG_AUDITORIA_ACESSAR
                    }
                ]
            }
        ];
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');

        themeLink.href = 'assets/theme/theme-' + theme + '.css';
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li  [hasRole]="child.role" [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                   class="ripplelink" *ngIf="!child.routerLink"
                    [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
                    <span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
                    
                    <span [ngStyle]="getStyle(child)">{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">{{child.label}}</div>
                </div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppComponent) { }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    getStyle(item) {
        if(item.style) {
            return item.style;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
