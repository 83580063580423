import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AbstractLogin} from '@nuvem/angular-base';
import {Usuario} from '../usuario/usuario';

@Component({
  selector: 'app-login-success',
  template: ''
})
export class LoginSuccessComponent implements OnInit {

    constructor(private loginService: AbstractLogin<Usuario>, private router: Router) {}

    ngOnInit() {
        this.loginService.setUserDetails().subscribe(value => {
            console.log(value);
            console.log(value.roles);
            console.log(value.roles.indexOf("ROLE_SEM_ACESSO_AO_SISTEMA_USUARIO_SEM_CERTIFICADO_OU_SERVIDOR_SEM_CERTIFICADO_SERPRO"));
            if(value.roles != null && value.roles.indexOf("ROLE_SEM_ACESSO_AO_SISTEMA_USUARIO_SEM_CERTIFICADO_OU_SERVIDOR_SEM_CERTIFICADO_SERPRO") > -1){
                this.router.navigateByUrl('login-failed').then(() => window.location.reload(true));
            } else {
                this.router.navigate(['']).then(() => window.location.reload(true));
            }
        });
    }
}
