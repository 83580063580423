import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    version: string = environment.VERSION;
    representado: string;
    cpfRepresentado: string;

    constructor(public app: AppComponent) {
        const user = JSON.parse(environment.auth.storage.getItem("user"));
        this.representado = user && user.representado ? user.representado.nome : null;
        this.cpfRepresentado = user && user.representado ? user.representado.cpfCnpj : null;
    }

}
