export class NumeroUtils {

  public static desformatar(v: string) {     
    return (!v) ? v : v.replace(/\D/g, ''); // Remove tudo o que e digito      
  }

  public static toString(v: number): string {
    if (!v && v !== 0) return '';
    return v.toString();
  }

  public static calcularDigito(v: string, peso: number[]): string {
    let soma = 0;
    for (let indice = v.length - 1, digito; indice >= 0; indice--) {
      digito = +(v.substring(indice, indice + 1));
      soma += digito * peso[peso.length - v.length + indice];
    }
    soma = 11 - soma % 11;
    return soma > 9 ? '0' : soma.toString();
  }
}
