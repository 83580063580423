import {
  Injector,
  Pipe,
  PipeTransform
} from '@angular/core';


@Pipe({
name: 'cpfCnpjPipeAppModule'
})
export class CpfCnpjPipeAppModule implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: string, pipeArgs: string[]): string {
      if(value.length > 13){
        return (value.substring(0, 2) + "." + value.substring(2, 5) + "." + value.substring(5, 8) + "/" + value.substring(8, 12) + "-" + value.substring(12, 14)); 
      }
      return (value.substring(0, 3) + "." + value.substring(3, 6) + "." + value.substring(6, 9) + "-" + value.substring(9, 11)); 
  }

}