import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { CpfCnpjDirective } from './cpf-cnpj-mask.directive';

@NgModule({
    declarations: [
        CpfCnpjDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CpfCnpjDirective
    ]
})
export class AppDirectivesModule { }
