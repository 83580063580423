import { Directive, DoCheck, Self, ElementRef, HostListener, AfterViewInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CNPJUtils, CPFUtils, NiUtils } from '../util/cpf-cnpj.util';

export const CPF_LENGTH = 11;
export const CNPJ_LENGTH = 14;

@Directive({
  selector: '[cpfCnpjMask][formControlName],[cpfCnpjMask][formControl]'
})
export class CpfCnpjDirective implements AfterViewInit, DoCheck {

  private nativeElement: HTMLInputElement;

  constructor(@Self() private ngControl: NgControl,
              private el: ElementRef) { }

  ngAfterViewInit() {
    this.nativeElement = this.el.nativeElement;
  }

  ngDoCheck() {
    if (this.nativeElement && this.ngControl.control.value != this.nativeElement.value) {
      this.onInput();
    }
  }

  @HostListener('input') onInput() {
    const rawValue = this.nativeElement.value || '';
    const maskedValue = this.applyMask(rawValue);

    // atualiza o valor do elemento html input
    if (this.nativeElement.value !== maskedValue) {
      this.nativeElement.value = maskedValue;
    }

    // atualiza o valor do control
    if (this.ngControl.control.value !== maskedValue) {
      this.ngControl.control.setValue(maskedValue, {emitEvent: false, emitModelToViewChanges: false});
    }
  }

  private applyMask(value: string): string {

    // aplica a regex de limpeza de caracteres indesejados
    value = value.replace(/\D/g,'');

    // aplica a remocao de caracteres fora do limite maximo
    value = value.slice(0, CNPJ_LENGTH);

    const niLength = value.length;

    // aplica a regex de mascaramento/formatacao
    if (niLength <= 11) {
      value = CPFUtils.mascarar(value);
    } else {
      value = CNPJUtils.mascarar(value);
    }

    return value;
  }
}
