import { Injector, Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'simNaoPipe'
})
export class SimNaoPipe implements PipeTransform {

    public constructor(private injector: Injector) { }

    transform(value: any, pipeArgs: any[]): any {
        return (value == 'S') ? 'Sim' : 'Não';
    }
}
